import { gql } from "graphql-request";

export const GetCompanyChannelById = gql`
    query GetCompanyChannelById($id: Int!) {
        companyChannel(id: $id) {
            channel {
                createdAt
                name
                displayName
                enabled
            }
            companyId
            displayName
            enabled
            createdAt
            company {
                name
            }
            fields {
                nodes {
                    companyProductId
                    config
                    createdAt
                    id
                    nodeId
                    topic
                    updatedAt
                    value
                    companyChannelId
                    companyId
                    companyIntegrationId
                    fieldConfigByConfig {
                        id
                        key
                        label
                        public
                        type
                    }
                }
            }
        }
    }
`;