import { phoneBaseMask } from "@meta";
import { getMaskFormatter } from "./mask";
const maskPhoneCache: Record<string | number, string> = {};

export const getPhoneMask = (val: string, raw = false): string => {
    const checkVal = raw ? val : transformFromPhone(val);
    const baseLen = 10;
    const maxLen = 13;
    const minLen = Math.max(checkVal.length, baseLen);
    const getLen = Math.min(minLen, maxLen);
    if (getLen in maskPhoneCache) return maskPhoneCache[getLen]!;

    const mask = [
        getLen > baseLen
            ? `+${"9".repeat(getLen - baseLen)} `
            : "",
        phoneBaseMask,
    ].join("");
    maskPhoneCache[getLen] = mask;
    return mask;
};

export const transformFromPhone = (val: string): string => val.replace(/[^0-9]/g, "");

export const transformToPhone = (val: string): string => {
    const mask = getPhoneMask(val, true);
    const formatter = getMaskFormatter(mask, { maskChar: "_" });
    return formatter(val, { pivotPoint: -1, excludeMaskSuffix: true }).pivot;
};