import dayjs from "dayjs";
import { dateFormat, internalDateFormat } from "@meta";
import { getDateFormatPatterns } from "./date";

export const namePartsToFull = (firstName: string, lastName: string): string => [
    firstName.trim(),
    lastName.trim(),
].filter(Boolean).join(" ");

export const fullNameToParts = (fullName: string): {
    first: string;
    last: string;
} => fullName.trim().split(" ").reduce((acc, name, idx, fullArr) => {
    if (idx === fullArr.length - 1 && idx > 0) {
        acc.last = name;
    } else {
        acc.first = [
            acc.first,
            name,
        ].filter(Boolean).join(" ");
    }
    return acc;
}, {
    first: "",
    last: "",
} as {
    first: string;
    last: string;
});

export const formatPostalCode = (postalCode: string): string => {
    const pattern = /([^\s]{1,3})\s*([^\s]*)/;
    const matches = pattern.exec(postalCode);
    if (!matches) return "";

    return [
        matches[1],
        matches[2],
    ].filter(Boolean).join(" ");
};

export const rawPostalCode = (postalCode: string): string => postalCode.replace(/\s*/g, "").toUpperCase();

export const addressToParts = (address: string): {
    number: string;
    street: string;
} => {
    const pattern = /^([^\s]+)\s*(.*)/i;
    const matches = pattern.exec(address) || [];
    return {
        number: matches[1] || "",
        street: matches[2] || "",
    };
};

export const leftPad = (str: string, len: number): string => {
    let repeatLen = len - str.length;
    if (repeatLen < 0) repeatLen = 0;
    return "0".repeat(repeatLen) + str;
};

export const dateToRaw = (val: string): string => {
    if (!val) return val;
    const patterns = getDateFormatPatterns(dateFormat);
    const dt = dayjs(val, patterns, true);
    if (!dt.isValid()) return "";
    return dt.format(internalDateFormat);
};
export const rawToDate = (val: string): string => {
    if (!val) return "";
    const dt = dayjs(val, internalDateFormat, true);
    if (!dt.isValid()) return "";
    return dt.format(dateFormat);
};

export const prettyNumber = (val: string | number, prec = 0): string => {
    const parsedVal = typeof val === "string"
        ? parseInt(val, 10)
        : val;
    if (parsedVal !== 0 && (!parsedVal || isNaN(parsedVal))) return "";
    return parsedVal.toFixed(prec)
        .replace(/(\d{1,3}(?!,))(?=(\d{3})+(?!\d))/g, "$1,");
};

export const toCurrency = (val: string | number, fixed = 2): string => {
    if (!val) return `${val}`;
    // const newVal = "$" + parseInt(`${val}`, 10).toFixed(fixed)
    //     .replace(/(\d{1,3}(?!,))(?=(\d{3})+(?!\d))/g, "$1,");
    const newVal = "$" + prettyNumber(val, fixed);
    return newVal;
};
export const fromCurrency = (val: string): string => {
    const newVal = val.replace(/[^\d.]/g, "");
    return newVal;
};

export const toFloat = (val: string, prec = 2): number => {
    if (typeof val === "undefined" || val === null) return 0;
    const numVal = parseFloat(fromCurrency(val.toString()));
    const strVal = (isNaN(numVal) ? 0 : numVal).toFixed(prec);
    return Number(strVal);
};

export const toProperCase = (val: string) => val.replace(/([^a-zA-Z0-9']|^)([a-z])/g, (_, p1, p2) => p1 + p2.toUpperCase());