export const WINDOW_ID = "FRAME_HANDLER";
export const IS_ERROR_INDICATOR = "IS_ERROR";

export const ANALYTICS_DEBUG_PARAM = "avaAnalyticsDebug";

export const AVA_TEST_LEAD_HEADER = "x-ava-test-lead";
export const ALS_COMPANY_ID_HEADER = "x-als-company-id";
export const ALS_WIDGET_ID_HEADER = "x-als-widget-id";
export const ALS_REFERRER_HEADER = "x-als-referrer";
export const ALS_ANALYTICS_ID = "x-analytics-id";

export const MIXPANEL_READY = "mixpanelReady";
export const MOUSEFLOW_READY = "mouseflowReady";
export const GA_READY = "gaReady";

export type ALL_READY_KEYS = (
    | typeof MIXPANEL_READY
    | typeof MOUSEFLOW_READY
    | typeof GA_READY
);