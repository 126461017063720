import { StateModel, StrictState } from "@autocorp/redux";
import { ContextDatalayer } from "@shared/types";

export type { ContextDatalayer };

const datalayerDefaultState: StrictState<ContextDatalayer> = {
    asset: undefined,
    booking: undefined,
    location: undefined,
};

export const DatalayerModel = new StateModel({
    name: "datalayer",
    state: datalayerDefaultState,
    actions: {
        setDatalayer(state?: ContextDatalayer) {
            return (state || {}) as StrictState<ContextDatalayer>;
        },
    },
    selectors: {
        asset(_: null, state) {
            const asset = state.asset;
            return {
                asset,
                display: asset?.listingTitle || [asset?.year, asset?.exteriorColor, asset?.make, asset?.model, asset?.trim].filter(Boolean).join(" "),
            };
        },
        booking(_: null, state) {
            const booking = state.booking;
            return {
                booking,
                display: [booking?.date, booking?.time].filter(Boolean).join(" at "),
            };
        },
        location(_: null, state) {
            const location = state.location;
            return {
                location,
                display: `Stock #${location?.stockId} at ${location?.locationId}`,
            };
        },
    },
});