export const durationFormat = "Years/Months";
export const internalDurationFormat = "MM-YYYY";

export const dateFormat = "DD/MM/YYYY";
export const internalDateFormat = "YYYY-MM-DD";
export const dateMask = dateFormat.replace(/[dmy]/ig, "9");

export const phoneBaseMask = "(999) 999-9999";
export const phoneMaxMask = "+999 (999) 999-9999";

export const numberBaseMask = "999";