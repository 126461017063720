import dayjs, { Dayjs } from "dayjs";
import { internalDateFormat } from "@meta";

const months = new Array(12).fill("").map((_, i) => dayjs().month(i));
const days = new Array(7).fill("").map((_, i) => dayjs().day(i));

export const monthNames = Object.freeze(months.map((month) => month.format("MMMM")));
export const shortMonthNames = Object.freeze(months.map((month) => month.format("MMM")));
export const dayNames = Object.freeze(days.map((day) => day.format("dddd")));
export const shortDayNames = Object.freeze(days.map((day) => day.format("ddd")));

const _getMonthName = (dt: Dayjs | Date, nameList: readonly string[]): string => {
    return nameList[(
        dayjs.isDayjs(dt)
            ? dt
            : dayjs(dt)
    ).month()]!;
};

export const getMonthName = (dt: Date | Dayjs): string => _getMonthName(dt, monthNames);
export const getShortMonthName = (dt: Date | Dayjs): string => _getMonthName(dt, shortMonthNames);

export const getFirstCalendarDay = (dt: Dayjs): Dayjs => {
    return dayjs(dt.format(internalDateFormat)).startOf("month").startOf("week");
};
export const getLastCalendarDay = (dt: Dayjs): Dayjs => {
    return dayjs(dt.format(internalDateFormat)).endOf("month").endOf("week");
};

export const getMonthMatrix = (dt?: Dayjs, singleDays = true): [string[], ...Dayjs[][]] => {
    const getDt = dt === undefined ? dayjs() : dt;

    const firstCalendarDay = getFirstCalendarDay(getDt);
    const lastCalendarDay = getLastCalendarDay(getDt);

    const matrix = [
        singleDays
            ? shortDayNames.map((d) => d[0])
            : [...shortDayNames],
    ] as [string[], ...Dayjs[][]];

    let newDt = firstCalendarDay;
    let week: Dayjs[] = [];
    do {
        week.push(newDt);
        if (week.length === 7) {
            matrix.push(week);
            week = [];
        }
        newDt = newDt.add(1, "d");
    } while (newDt.isSameOrBefore(lastCalendarDay, "date"));

    return matrix;
};

const dateFormatPatterns: Record<string, string[]> = {};

export const getDateFormatPatterns = (format: string): string[] => {
    if (format in dateFormatPatterns) return dateFormatPatterns[format]!;

    const patterns = [format];
    const shortYear = format.replace(/Y+/, "YY");
    if (shortYear !== format) patterns.push(shortYear);
    dateFormatPatterns[format] = patterns;
    return patterns;
};