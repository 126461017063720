import { gql } from "graphql-request";

export const SendNotification = gql`
    mutation SendNotification($leadId: UUID!) {
        triggerLeadNotification(leadId: $leadId) {
            leadId
            success
        }
    }
`;

export const RequestTwilioVerification = gql`
    mutation RequestVerification($leadId: UUID!, $type: VerificationChannel!) {
        requestVerification(leadId: $leadId, type: $type) {
            success
            leadId
            phoneVerifiedAt
        }
    }
`;

export const ConfirmTwilioVerification = gql`
    mutation ConfirmVerificationCode($leadId: UUID!, $type: VerificationChannel!, $code: String!) {
        confirmVerificationCode(leadId: $leadId, code: $code, type: $type) {
            success
            leadId
            phoneVerifiedAt
        }
    }
`;

export const UpdateLeadMeta = gql`
    mutation UpdateLeadMeta($leadId: UUID!, $companyId: Int!, $meta: JSON!) {
        updateLeadMeta(leadId: $leadId, companyId: $companyId, meta: $meta) {
            leadId
            meta
        }
    }
`;

export const GetLeadInfo = gql`
    query GetLeadInfo($accessToken: String!) {
        getLeadInfo(accessToken: $accessToken) {
            id
            firstName
            lastName
            dateOfBirth
            phoneNumber
            emailAddress
            postalCode
            provinceCode
            streetNumber
            street
            city
            additionalInformation
            
            company {
				id
				name
			}
            employmentStatus
            incomeAmount
            incomePeriod
        }
    }
`;