import { rootApiClient } from "@api/api-client";
import { StateModel, StrictState } from "@autocorp/redux";
import { frameHandler } from "@host";
import merge from "lodash/merge";

import type { IProductsState } from "./products";

export type Feedback = {
    leadId: string;
    companyId: number;
    feedback: string;
}

type ProductFeedback = {
    [product in keyof IProductsState]?: string;
}

export type LeadMeta = {
    productVariant?: string;
    feedback?: ProductFeedback;
}

export interface ICoreState {
    leadMeta: LeadMeta;
}

const defaultCoreState: StrictState<ICoreState> = {
    leadMeta: {},
};

export const CoreModel = new StateModel({
    name: "core",
    state: defaultCoreState,
    asyncActions: {
        updateProductFeedback({ leadId, companyId, feedback }: Feedback, model) {
            const state = model.state as ICoreState;
            const product = frameHandler.product!;
            const leadMeta = merge(
                state.leadMeta,
                {
                    ...state.leadMeta,
                    feedback: {
                        [`${product}`]: feedback,
                    },
                },
            );
            return rootApiClient.updateLeadMeta({ leadId, companyId, meta: leadMeta });
        },
    },
});
