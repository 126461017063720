import dayjs from "dayjs";
import utcTime from "dayjs/plugin/utc";
import timezoneSupport from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";

// Initialize the host frame handler
import "@host";

[
    customParseFormat,
    utcTime,
    isSameOrBefore,
    advancedFormat,
    timezoneSupport,
    isBetween,
].forEach((p) => dayjs.extend(p));