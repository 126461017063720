import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./en/english.json";
import french from "./fr/french.json";

const resources = {
    en: {
        translation: english,
    },
    fr: {
        translation: french,
    },
};

i18next
    .use(initReactI18next)
    .init({
        lng: "en", // if you're using a language detector, do not define the lng option
        debug: ACTIVE_ENV === "development",
        resources,
        fallbackLng: "en",
        supportedLngs: ["en", "fr"],
        load: "languageOnly",
        ns: "translation",
        defaultNS: "translation",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
        // set returnNull to false (and also in the i18next.d.ts options)
        // returnNull: false,
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ["br", "strong", "i", "u", "b"],
        },
    });

export default i18next;