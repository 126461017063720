import { StateModel, StrictState } from "@autocorp/redux";
import {
    toProperCase,
    namePartsToFull,
    fullNameToParts,
    addressToParts,
} from "@util/transforms";
import { mfq } from "@host";
import type { Company } from "@model/company";

export interface IUserState {
    leadId: string;

    fullName: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    dateOfBirth: string;
    address: string;
    streetNumber: string;
    street: string;
    unit: string;
    postalCode: string;
    city: string;
    province: string;
    country: string;

    yearlyIncome: number;
    employmentStatus: string;

    distCompany?: Company;
    phoneVerifiedAt?: string;
}

const defaultUserState: StrictState<IUserState> = {
    leadId: "",

    fullName: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    dateOfBirth: "",
    address: "",
    streetNumber: "",
    street: "",
    unit: "",
    postalCode: "",
    city: "",
    province: "",
    country: "CA",

    yearlyIncome: 0,
    employmentStatus: "",

    distCompany: undefined,
    phoneVerifiedAt: undefined,
};

export const UserModel = new StateModel({
    name: "user",
    state: defaultUserState,
    updaters: {
        leadId: (newVal, state) => {
            if (state.leadId !== newVal) {
                mfq.set("leadId", newVal);
            }
            return {};
        },
        firstName: (newVal, state) => {
            const valFormatted = toProperCase(newVal);
            return {
                fullName: namePartsToFull(valFormatted.trim(), state.lastName),
                firstName: valFormatted,
            };
        },
        lastName: (newVal, state) => {
            const valFormatted = toProperCase(newVal);
            return {
                fullName: namePartsToFull(state.firstName, valFormatted.trim()),
                lastName: valFormatted,
            };
        },
        fullName: (newVal) => {
            const valFormatted = toProperCase(newVal);
            const nameParts = fullNameToParts(valFormatted);
            return {
                firstName: nameParts.first,
                lastName: nameParts.last,
                fullName: valFormatted,
            };
        },
        address: (newVal) => {
            const valFormatted = toProperCase(newVal);
            const addrParts = addressToParts(valFormatted.trim());
            return {
                address: valFormatted,
                streetNumber: addrParts.number,
                street: addrParts.street,
            };
        },
        city: (newVal) => {
            return {
                city: toProperCase(newVal),
            };
        },
        postalCode: (newVal) => {
            return {
                postalCode: newVal.toUpperCase(),
            };
        },
    },
});