type MaskInterpolator = {
    "A": RegExp;
    "a": RegExp;
    "L": RegExp;
    "9": RegExp;
    "*": RegExp;
}
type MaskInterpolatorKeys = keyof MaskInterpolator;

const interpolator: MaskInterpolator = {
    "A": /[A-Z]/,
    "a": /[a-z]/,
    "L": /[A-Za-z]/,
    "9": /[0-9]/,
    "*": /[a-zA-Z0-9]/,
};

type MaskProcessor = (string | RegExp)[];
type MaskFormatterReturn = {
    masked: string;
    formatted: string;
    pivot: string;
    raw: string;
};

export type MaskFormatter = (val: string, options?: {
    pivotPoint?: number,
    excludeMaskSuffix?: boolean,
}) => MaskFormatterReturn;

export type MaskFormatterFactory = typeof getMaskFormatter;

type MaskFormatterCache = {
    [k: string]: MaskFormatter;
}

const masks: MaskFormatterCache = {};

enum CharType {
    mask,
    placeholder,
    literal,
}

const DEFAULT_MASK_CHAR = "_";

interface IGetMaskFormatterOptions {
    maskChar?: string;
    capLength?: boolean;
}

export const getMaskFormatter = (mask: string, {
    maskChar = DEFAULT_MASK_CHAR,
    capLength = false,
} = {} as IGetMaskFormatterOptions) => {
    maskChar = maskChar[0]!;
    const identifier = JSON.stringify({mask, char: maskChar});
    if (masks[identifier]) return masks[identifier] as MaskFormatter;

    let stopTranspose = -1;
    let nextLiteral = false;
    const processor: MaskProcessor = mask.split("").reduce((acc, c, i, arr) => {
        const isInterpolator = (chr: string) => chr in interpolator;

        if (c === "\\" && isInterpolator(arr[i + 1]!)) {
            nextLiteral = true;
            return acc;
        }

        if (isInterpolator(c) && !nextLiteral) {
            if (stopTranspose === -1) stopTranspose = i;
            acc.push(interpolator[c as MaskInterpolatorKeys]);
        } else {
            acc.push(c);
        }

        nextLiteral = false;
        return acc;
    }, [] as MaskProcessor);

    let last: MaskFormatterReturn;

    const formatter: MaskFormatter = (val, {
        pivotPoint = -1,
        excludeMaskSuffix = false,
    } = {}) => {
        const raw: string[] = [];
        const masked: string[] = [];
        const formatted: string[] = [];
        const pivot: string[] = [];
        let formattedDone = false;
        let pivotDone = false;
        let valPos = 0;
        let transpose = true;

        let maskChars: string[] = [];

        if (processor.length === 0) {
            return {
                masked: val,
                formatted: val,
                pivot: pivotPoint > -1 ? val.slice(0, pivotPoint) : val,
                raw: val,
            };
        }

        const pushVal = (c: string, type: CharType): void => {
            if (type === CharType.literal) {
                raw.push(c);
            }

            masked.push(c);
            formattedDone = formattedDone || (valPos >= val.length && type === CharType.placeholder);
            if (!formattedDone) formatted.push(c);

            pivotDone = (
                pivotDone ||
                    (pivotPoint < 0 && valPos > val.length) ||
                    (pivotPoint >= 0 && valPos > pivotPoint) ||
                    (type === CharType.placeholder)
            );

            if (type === CharType.mask) {
                if (!pivotDone) maskChars.push(c);
            } else {
                if (!pivotDone) {
                    pivot.push(...maskChars, c);
                } else {
                    if (!excludeMaskSuffix && maskChars.length > 0) pivot.push(...maskChars);
                }
                maskChars = [];
            }
        };

        for (const [procPos, procVal] of processor.entries()) {
            if (procVal instanceof RegExp) {
                if (val[valPos] === maskChar) {
                    const toPos = valPos - 1;
                    val = val.slice(0, toPos < 0 ? 0 : toPos);
                }
                let usePlaceholder = valPos >= val.length;
                if (valPos < val.length) {
                    let literalInserted = false;
                    while (valPos < val.length) {
                        const curInputVal = val[valPos]!;
                        valPos++;
                        if (procVal.test(curInputVal)) {
                            pushVal(curInputVal, CharType.literal);
                            literalInserted = true;
                            break;
                        }
                    }
                    usePlaceholder = usePlaceholder || (
                        !literalInserted && valPos >= val.length && procPos < processor.length
                    );
                }
                if (usePlaceholder) {
                    pushVal(maskChar, CharType.placeholder);
                }
            } else {
                if (transpose && val[valPos] === procVal) {
                    valPos++;
                } else {
                    transpose = false;
                }
                pushVal(procVal, CharType.mask);
            }
        }

        const next: MaskFormatterReturn = {
            masked: masked.join(""),
            formatted: formatted.join(""),
            pivot: pivot.join(""),
            raw: raw.join(""),
        };

        if (
            capLength &&
            last &&
            val.length >= processor.length &&
            next.formatted.length === last.formatted.length
        ) {
            return {
                ...last,
                pivot: pivotPoint > -1 ? last.masked.slice(0, pivotPoint - 1) : last.masked,
            };
        }

        return last = next;
    };

    masks[identifier] = formatter;
    return formatter;
};